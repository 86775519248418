
import { Link, graphql } from "gatsby";
import React, {useState} from 'react';
import styles from "../../../components/innerPage.module.css"
import Footer from '../../../components/UkResidents/NonHCP/UkNonHCPFooter';
import {useDispatch} from "react-redux"
import Header from '../../../components/UkResidents/NonHCP/header';
import AlkinidUkiePdf from '../../../images/alkindi-ukie-pil-alkukpil0002bfrom-1-march-2020.pdf'
import ScrollAnimation from 'react-animate-on-scroll';
import VideoPoster from "../../../images/videoPoster.jpg"
import { Icon } from 'react-icons-kit'
import {ic_close} from 'react-icons-kit/md/ic_close'
import {showUkResidentsNonHcpDisclaimerPopup} from "../../../actions/ukResidents/nonHcp/"
import {toggleUkResidentsNonHcpDisclaimerPopup} from "../../../controllers/ukResidents/nonHcp/disclaimer"
import { TitleTwoTone } from "@material-ui/icons";
import {sortByLatestYear, addDisclaimersToCmsLinks, capitalizeFirstLetter, isNullUndefined, richTextOptions} from "../../../util/index"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"
import { getDefaultUkResidentsNonHcpDisclaimerPopupConfigs, config } from '../../../util/configs'
import videoplaceholder from "../../../images/alkindi_cmyk.jpg"
import queryString from "query-string"
import SEO from "../../../components/seo"
import { ValidateRoute } from '../../../util/route-helper';
import TemplateWrapper from "../../../components/TemplateWrapper"


export const pageQueryVideoPdfTemplateUkNonHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulVideoPdfTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
          spaceId
          slug
          title
          bannerImage {
            file {
              url
            }
          }
          BodyText {
            json
          }
             bottomBodyText {
            json
          }
           
          flag {
            file {
              url
            }
          }
          video {
            file {
              url
            }
          }
          videoPoster {
            file {
              url
            }
          }
          videoSectionSideText {
            json
          }
          videoSectionTopText {
            json
          }
          parentMenu {
            title
            slug
          }
          isPlaceholder
        }
    }
`

const VideoPdfTemplatePageUkNonHcp = (props) => {

    const [isActive, setIsActive] = useState(false)
    
    const dispatch = useDispatch();

  const { videoPoster, spaceId, slug, title, bannerImage, BodyText, bottomBodyText, flag, video, videoSectionSideText, videoSectionTopText, parentMenu, isPlaceholder} = props.data.contentfulVideoPdfTemplate;
 
    let richTextBody = documentToReactComponents(isNullUndefined(BodyText) ? BodyText : BodyText.json, richTextOptions)
  let bottomRichTextBody = documentToReactComponents(isNullUndefined(bottomBodyText) ? bottomBodyText : bottomBodyText.json, richTextOptions)
    let richTextVideoSide = documentToReactComponents(isNullUndefined(videoSectionSideText) ? videoSectionSideText : videoSectionSideText.json, richTextOptions)
    let richTextVideoTop = documentToReactComponents(isNullUndefined(videoSectionTopText) ? videoSectionTopText : videoSectionTopText.json, richTextOptions)

    let bodyHtml = ReactDOMServer.renderToStaticMarkup(richTextBody)
  let bodyHtmlBottom = ReactDOMServer.renderToStaticMarkup(bottomRichTextBody)
    let videoSideHtml = ReactDOMServer.renderToStaticMarkup(richTextVideoSide)
    let videoTopHtml = ReactDOMServer.renderToStaticMarkup(richTextVideoTop)

    const showExternalLinkDisclaimer = (externalUrl) => {
        let okCallback = () => window.open (externalUrl, "_blank")
        let closeCallback = () => {}
        let actionConfig = getDefaultUkResidentsNonHcpDisclaimerPopupConfigs(okCallback, closeCallback)
        dispatch(showUkResidentsNonHcpDisclaimerPopup(actionConfig));
    }

    let [veevaDisclaimer, setVeevaDisclaimer] = React.useState(false);


    React.useEffect(() => {
      ValidateRoute();
        let {veevaDisclaimer} = queryString.parse(props.location.search || "");
  
        if(veevaDisclaimer){
          setVeevaDisclaimer(true);
          showExternalLinkDisclaimer(config.defaultBaseUrl)
        }
  
    }, []);

    if(isPlaceholder){
      return <React.Fragment />
    }
    
    return ( 
        <TemplateWrapper>
           <Header/>
         <SEO title={`${capitalizeFirstLetter(title)} | UK Residents | Non-HCP`} />
        
      
            <div className={styles.banner} style={{background:`url(${isNullUndefined(bannerImage) ? "":bannerImage.file.url})`, backgroundPositionY: 'bottom'}}>
            <div className={styles.overlay}></div>
                        <div className="container">
                           <div className="row">
                               <div className="col-12 col-sm-12 col-md-12">
                                
                               <h1 className="">
                                   {title}
                               </h1>
                               <ol className="custom_breadcrumb">
                               <li className="breadcrumb-item"><Link to="/UkResidents/Non-HCP/home">Home</Link></li>
                                    <li className="breadcrumb-item">
                                    <Link to={`/UkResidents/Non-HCP/${parentMenu.slug}`}>
                                        {parentMenu.title}
                                    </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {title}
                                    </li>
                                </ol>
                               </div>
                               </div>
                        </div>
            </div>
            <div className={styles.video_content} style={{minHeight: 'calc(100vh - 587px)'}}>
                    <div className="container pt-1 pb-1">
         
                    <div className="row">
                 
                    <div className="col-12 col-sm-12 col-md-12 text-left">
                    <div className={styles.VideoContent_BodyText}>
                    {ReactHtmlParser(bodyHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                    </div>
                    </div>

                </div>
                    </div>
          <div className="container pt-1 pb-1 mt-3">

            <div className="row">
              <div className="col-12">
                {/* <img src={isNullUndefined(pageBanner) ? "" : pageBanner.file.url} style={{ width: '100% ', maxWidth: 'unset' }} /> */}
              </div>
            </div>
          </div>
                    <div className={(!isNullUndefined(videoSectionTopText) || !isNullUndefined(video) || !isNullUndefined(videoSectionSideText)) ? styles.video_section : ""}>
                        <div className="container">
                        {!isNullUndefined(videoSectionTopText) && ReactHtmlParser(videoTopHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                           
                            {!isNullUndefined(video) && <video width="100%" controls  poster={!isNullUndefined(videoPoster) ? videoPoster.file.url : ""}>
                                    <source src={isNullUndefined(video) ? "" : video.file.url} type="video/mp4"/>
                                    </video>}
                             
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                              <div className={styles.videosectionSidetext}>
                            {!isNullUndefined(videoSectionSideText) && ReactHtmlParser(videoSideHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer)})}
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>

          <div className="container pt-1 pb-1">

            <div className="row">

              <div className="col-12 col-sm-12 col-md-12 text-left mt-4">
                <div className={styles.VideoContent_BodyText}>
                  {ReactHtmlParser(bodyHtmlBottom, { transform: (node, index) => addDisclaimersToCmsLinks(node, index, showExternalLinkDisclaimer) })}
                </div>
              </div>

            </div>
          </div>
      
      
           </div>
         
            <Footer/>
                {!isNullUndefined(video) && <div>
                                 <div className={`${styles.video_wrap} ${isActive ? `${styles.active_video}` : ""} `} >
                                   
                                    <div className={`${styles.video_content}  `} >
                                    <div className={styles.close_video}><Icon icon={ic_close} /></div>
                                    <video  height="" controls  poster={!isNullUndefined(videoPoster) ? videoPoster.file.url : ""}>
                                    <source src={isNullUndefined(video) ? "" : video.file.url} type="video/mp4"/>
                                    </video>
                                    </div>
                                </div>
                                </div>}
        </TemplateWrapper>
     );
}

export default VideoPdfTemplatePageUkNonHcp